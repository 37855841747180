.user {
  &-popup {
    margin-top: 0;
    padding: 1.8rem 2.8rem 1.6rem;
    width: 30.1rem;
    text-align: left;

    @media screen and (max-width: $hd - 30) {
      left: -4.3rem;
    }

    @media screen and (max-width: $hd - 100) {
      left: -8.5rem;
    }

    @media screen and (max-width: $tablet) {
      left: 0;
      top: 0;
      position: fixed;
      width: 100%;
      height: 100%;
      box-shadow: none;
      border-radius: 0;
      padding: 0 0 2.4rem;
      overflow-y: auto;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-width: 0 0.8rem 1rem;
      border-bottom-color: $white;
      position: absolute;
      top: -1rem;
      left: 3.5rem;
      z-index: 2;

      @media screen and (max-width: $hd - 30) {
        left: 5.3rem;
      }

      @media screen and (max-width: $hd - 100) {
        left: 9.3rem;
      }

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    &_name {
      font-weight: $font-bold;
      margin: 0 0 0.5rem;

      &.mobile {
        display: none;
      }

      @media screen and (max-width: $tablet) {
        color: #2d3541;
        font-size: 2.4rem;
        margin: 0;
      }
    }

    &_phone {
      margin: 0;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    &_menu {
      border-bottom: none !important;
      margin-top: 1.5rem;

      li {
        padding: 1.8rem 0 0 !important;

        &:first-child {
          padding-top: 1.5rem !important;
        }

        @media screen and (max-width: $tablet) {
          padding: 1rem 0 0 !important;

          &:first-child {
            padding-top: 0 !important;
          }
        }

        .dropdown-item {
          padding: 0;
          text-transform: capitalize;

          @media screen and (max-width: $tablet) {
            background-color: $white;
            border: 0.1rem solid rgba(213, 221, 224, 0.4);
            border-radius: 0.8rem;
            display: flex;
            align-items: center;
            font-size: 1.7rem;
            font-weight: $font-medium;
            height: 6rem;
            padding: 0 3rem 0 5.4rem;
            position: relative;

            &:hover,
            &:focus,
            &.active,
            &:active {
              background-color: $white;
            }

            &:after {
              background: url(../../images/ic-arrow_d.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 0.7rem;
              height: 1.2rem;
              position: absolute;
              top: 50%;
              right: 2.5rem;
              transform: translateY(-50%);
            }

            &.point {
              &-data {
                &:before {
                  background: url(../../images/ic-profile.svg) 0 0 no-repeat;
                  background-size: cover;
                  content: '';
                  display: block;
                  width: 1.6rem;
                  height: 1.6rem;
                  position: absolute;
                  left: 2rem;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }

              &-history,
              &-settings,
              &-credits {
                &:before {
                  background: url(../../images/ic-history_m.svg) 0 0 no-repeat;
                  background-size: cover;
                  content: '';
                  display: block;
                  width: 1.5rem;
                  height: 2rem;
                  position: absolute;
                  left: 2rem;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: $tablet) {
        border-top: none !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    &_logout {
      border-top: 0.1rem solid $gray-border;
      color: $main-text;
      font-weight: $font-medium;
      margin: 1.7rem 0 0;
      padding-top: 1.6rem;

      @media screen and (max-width: $tablet) {
        border-top: none;
        margin: 0 2.4rem;
      }

      @media screen and (max-width: $smartphone-smallest) {
        border-top: none;
        margin: 0 1.5rem;
      }

      a {
        color: $main-text;

        @media screen and (max-width: $tablet) {
          background-color: #f7f8f9;
          border: 0.1rem solid rgba(213, 221, 224, 0.4);
          border-radius: 0.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.7rem;
          font-weight: $font-medium;
          height: 6rem;

          span {
            background: url(../../images/ic-logout_m.svg) 100% 50% no-repeat;
            background-size: 2.1rem 2rem;
            padding-right: 3.6rem;
          }
        }
      }
    }
  }
}
