.catalog {
  &-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.4rem;

    @media screen and (max-width: $hd - 60) {
      display: block;
      padding-bottom: 0;
    }

    @media screen and (max-width: $smartphone) {
      margin-bottom: 1.5rem;
      min-height: 6.46rem;
    }

    &_title {
      flex: 0 0 25%;
      max-width: 25%;

      @media screen and (max-width: $hd - 60) {
        max-width: none;
        padding: 1.4rem 0;
      }

      .btn {
        border-color: $subcategory;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 20.3rem;

        span[class*='icon-'] {
          margin-left: 2rem;

          @media screen and (max-width: $smartphone) {
            margin-left: 0;
          }
        }

        &.back {
          span[class*='icon-'] {
            margin: 0 2rem 0 0;

            @media screen and (max-width: $smartphone) {
              margin-right: 0;
            }
          }
        }

        &:hover {
          background-color: $bg-social;
          color: $main-text;
        }

        &:focus,
        &:active {
          background-color: $bg-social;
          color: $subcategory;

          .icon-arrow {
            background-image: url(../../images/ic-arrow_b.svg);
          }
        }

        @media screen and (max-width: $smartphone) {
          font-weight: $font-medium;
          font-size: 1.2rem;
          height: 3rem;
          justify-content: space-between;
          line-height: 3rem;
          max-width: 10.5rem;
          padding: 0 1.2rem;
        }
      }
    }

    h1 {
      margin-bottom: 0;
      text-align: left;

      @media screen and (max-width: $hd - 60) {
        text-align: center;
      }

      @media screen and (max-width: $tablet-small) {
        margin-bottom: 1.5rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
    }

    &_search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .search {
        &-form {
          flex: 0 0 60rem;
          width: 60rem;

          @media screen and (max-width: $tablet) {
            width: 50rem;
            flex: 0 0 50rem;
          }

          @media screen and (max-width: $tablet-middle) {
            margin: 0 auto 2rem;
          }

          @media screen and (max-width: $tablet-small) {
            display: none;

            &.show {
              display: flex;
              flex: 0 0 100%;
              width: 100%;
              margin-left: 0;
              position: absolute;
              bottom: 0;
              left: 0;
              margin-bottom: 2.5rem;

              .global-search {
                position: absolute;
                left: 0;
                bottom: -2.5rem;
              }
            }
          }

          @media screen and (max-width: $smartphone + 40) {
            &.show {
              margin-bottom: 5.5rem;
              position: relative;
            }
          }

          &_open {
            background: url(../../images/ic-search.svg) 50% 50% no-repeat;
            background-size: 1.6rem 1.6rem;
            border: 0.1rem solid $border;
            border-radius: 0.4rem;
            display: none;
            height: 4.8rem;
            width: 4.8rem;
            position: absolute;
            top: 0;
            right: 0;

            @media screen and (max-width: $tablet-small) {
              display: block;
            }
          }
        }
      }

      .btn {
        padding: 0 3rem;
        white-space: nowrap;

        &.active {
          border-color: $subcategory;
          color: $subcategory;
        }

        @media screen and (max-width: $smartphone) {
          font-size: 1.2rem;
          height: 3rem;
          line-height: 3rem;
          padding: 0 1rem;
          white-space: nowrap;
        }
      }

      &-left {
        width: 50%;
        padding-right: 1.5rem;
        display: none;

        @media screen and (max-width: $tablet-small) {
          display: block;
        }

        @media screen and (max-width: $smartphone + 40) {
          width: 100%;
          padding-right: 6.3rem;
        }
      }

      &-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.3s linear;

        @media screen and (max-width: $hd - 60) {
          width: 100%;
        }

        @media screen and (max-width: $tablet-middle) {
          display: block;
        }

        @media screen and (max-width: $tablet-small) {
          display: block;
          width: 50%;
          padding-left: 0;
          padding-right: 6.3rem;

          &.show {
            position: static;

            .search-form_open {
              background-color: $subcategory;
              background-image: url(../../images/ic-search-w.svg);
              border-color: $subcategory;
            }
          }
        }

        @media screen and (max-width: $smartphone + 40) {
          padding: 2rem 0 0;
          width: 100%;
        }
      }

      @media screen and (max-width: $tablet-small) {
        &.open {
          padding-bottom: 12rem;
        }

        &.sticky {
          position: fixed;
          top: 8rem;
          left: 0;
          background-color: $white;
          border-top: 0.1rem solid $border;
          box-shadow: 0 0.3rem 0.4rem rgba($shadow, 0.15);
          padding: 15px;
          width: 100%;
          z-index: 1092;

          .catalog-filter_search-right.show {
            .search-form_open {
              top: 15px;
              right: 15px;
            }
          }

          .search-form.show {
            margin: 0 15px 4.5rem;
            width: calc(100% - 30px);
          }

          &.open {
            padding-bottom: 12rem;
          }
        }
      }

      @media screen and (max-width: $smartphone + 40) {
        display: block;

        &.open {
          padding-bottom: 0;
        }

        &.sticky {
          &.open {
            padding-bottom: 0;
          }
        }
      }

      @media screen and (max-width: $smartphone) {
        &.sticky {
          top: 5.6rem;
          padding: 1.2rem 15px;

          .catalog-filter_search-right.show {
            .search-form_open {
              top: 1.2rem;
            }
          }

          .search-form.show {
            margin: 0 15px 2.5rem;
          }
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        &.sticky {
          padding: 0.9rem 15px;

          .catalog-filter_search-right.show {
            .search-form_open {
              top: 0.9rem;
            }
          }
        }
      }

      @media screen and (max-width: $smartphone-small) {
        &.sticky {
          top: 5.05rem;
        }
      }
    }

    &_btn {
      display: flex;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: auto;
      padding: 1.5rem 0;
      margin: 0 6rem;

      li {
        margin-left: 2.5rem;

        &:first-child {
          margin: 0;
        }
      }

      @media screen and (max-width: $smartphone) {
        margin-bottom: 2.2rem;

        li {
          margin: 0 0 0 0.5rem;
        }
      }
    }

    &-con {
      margin-bottom: 1.7rem;
    }

    &_search {
      .scroll-wrapper > .scroll-content {
        overflow-y: hidden !important;
      }
    }

    &_open {
      display: none;
      background: $bg-orange;
      border-radius: 0.4rem;
      color: $subcategory;
      font-weight: $font-bold;
      padding: 0 5rem 0 2rem;
      height: 4.8rem;
      position: relative;

      &:after {
        background: url(../../images/ic-arrow-drop-b.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.49rem;
        height: 0.82rem;
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
      }

      @media screen and (max-width: $tablet-small) {
        display: flex;
        align-items: center;
      }
    }

    &-sort {
      .category-filters_list {
        margin-left: -0.8rem;
      }
    }
  }

  &-item {
    .item-food {
      height: 100%;
    }
  }

  &-sort {
    width: 28rem;
    flex: 0 0 28rem;
    display: flex;
    align-items: center;
    margin-left: 3.3rem;

    @media screen and (max-width: $tablet) {
      margin-left: 1.5rem;
    }

    @media screen and (max-width: $tablet-middle) {
      float: right;
      margin-left: 0;
    }

    @media screen and (max-width: $tablet-small) {
      float: none;
      padding-left: 0;
    }

    .caption {
      font-size: 1.4rem;
      font-weight: $font-bold;
      margin-right: 0.8rem;
    }

    .dropdown {
      &-toggle {
        border: none;
        font-size: 1.4rem;
        padding: 0 2.6rem 0 0;
        height: auto;
        position: relative;
        text-align: left;

        &:before {
          background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 1.2rem;
          height: 0.72rem;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        p {
          border-bottom: 0.1rem dashed $text;
          color: $text;
          font-size: 1.4rem;
          margin: 0;
          padding: 0;
        }
      }

      &-menu {
        font-family: $font-family-second;
        right: 0;
        margin-left: 0;
        padding: 1rem 2rem 1.5rem;

        &:before {
          left: auto;
          right: 1.8rem;
        }

        &:after {
          left: auto;
          right: 1.7rem;
        }

        .dropdown-item {
          font-family: $font-family-base;
          padding: 0.4rem 0 0;

          &.active {
            color: $subcategory;
            font-weight: $font-bold;
          }
        }
      }
    }

    p {
      border-bottom: 0.1rem solid $gray-border;
      color: $add-text;
      font-family: $font-family-base;
      font-size: 1.6rem;
      margin-bottom: 0.4rem;
      padding-bottom: 0.7rem;
    }
  }

  &-menu {
    font-size: 1.6rem;
    font-weight: $font-bold;

    &_link {
      background-color: $white;
      border-radius: 0.4rem;
      display: block;
      line-height: 1.28;
      padding: 1rem 3rem 1rem 1rem;
      transition: all 0.2s ease-in;
      position: relative;

      &:after {
        background: url(../../images/ic-arrow-drop.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.49rem;
        height: 0.82rem;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%) rotate(-90deg);
      }
    }

    &_item {
      &.active {
        .catalog-menu_link {
          background-color: rgba($subcategory, 0.15);
          color: $subcategory;

          &:after {
            background-image: url(../../images/ic-arrow-drop-b.svg);
          }
        }
      }

      &.open {
        .catalog-menu_link {
          &:after {
            transform: translateY(-50%) rotate(0deg);
          }
        }
      }
    }
  }

  &-submenu {
    padding: 0.5rem 0 1.7rem 2rem;

    &_link {
      display: block;
      font-weight: $font-regular;
      padding: 0.7rem 0.8rem;
    }

    &_item {
      &.active {
        .catalog-submenu {
          &_link {
            color: $subcategory;
            font-weight: $font-bold;
          }
        }
      }
    }

    &_submenu {
      font-size: 1.6rem;
      line-height: 1.5;
      margin: 0 0 0.5rem 1.7rem;

      .active {
        a {
          color: $subcategory;
          font-weight: $font-medium;
        }
      }
    }
  }

  &-left {
    .title,
    .catalog-close {
      display: none;
    }

    @media screen and (max-width: $tablet-small) {
      display: none;

      &.open {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        background-color: rgba($main-text, 0.8);
        flex: 0 0 100%;
        max-width: 100%;
        height: 100%;
        overflow-y: auto;
        z-index: 1095;

        .catalog {
          &-left_mobile {
            background-color: $white;
            margin-left: -15px;
            max-width: 92%;
            position: relative;

            .title {
              color: $main-text;
              display: block;
              font-weight: $font-medium;
              font-size: 2.2rem;
              padding: 1.6rem 1.4rem 0.5rem;
              text-transform: uppercase;
            }
          }

          &-close {
            background: url(../../images/ic-close.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 1.6rem;
            height: 1.6rem;
            position: absolute;
            top: 2rem;
            right: 2rem;
          }

          &-menu {
            &_item {
              border-left: none;
              border-right: none;

              &.active {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $smartphone) {
      &.open {
        .catalog {
          &-close {
            width: 1.2rem;
            height: 1.2rem;
            top: 2.4rem;
            right: 2.2rem;
          }

          &-menu {
            font-size: 1.6rem;
            line-height: 1.63;

            &_link {
              font-size: 1.4rem;
              padding-top: 1rem;
              padding-bottom: 0.9rem;
            }
          }

          &-submenu {
            padding-bottom: 1rem;

            &_submenu {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  &-right {
    .category-tabs_col {
      flex: 0 0 50%;
      width: 50%;

      @media screen and (max-width: $tablet-small) {
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }

  &-section {
    padding-top: 5rem;
  }

  &-popup {
    background-color: $white;
    box-shadow: 0 1rem 5rem rgba($text, 0.4);
    display: none;
    font-size: 1.4rem;
    position: absolute;
    top: 11.4rem;
    left: 0;
    width: 100%;
    height: 59.2rem;
    z-index: 501;

    &.open {
      display: flex;
    }

    &_personal {
      display: none;
    }

    @media screen and (max-width: $tablet) {
      border-top: 0.8rem solid $bg-blue-gray;
      box-shadow: none;
      top: 5.2rem;
      height: calc(100% - 5.2rem);
    }

    @media screen and (max-width: $smartphone-middle) {
      top: 10.2rem;
      height: calc(100% - 10.2rem);
    }

    @media screen and (max-width: $smartphone-middle) {
      &_personal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $border;
        border-bottom: 0.1rem solid $border;
        box-shadow: inset 0 0.4rem 0.4rem rgba($text, 0.04);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7rem;

        a {
          flex: 0 0 33.333333%;
          width: 33.333333%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 6.9rem;
          position: relative;

          &:before {
            background-color: $border;
            content: '';
            display: block;
            width: 0.1rem;
            height: 4.6rem;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          .icon {
            &-user {
              margin-bottom: 0.8rem;

              path {
                fill: $placeholder;
              }

              &:hover {
                path {
                  fill: $subcategory;
                }
              }
            }

            &-favourite {
              margin-bottom: 0.65rem;

              path {
                fill: $placeholder;
              }

              &:hover {
                path {
                  fill: $subcategory;
                }
              }
            }
          }

          &.link {
            &-user,
            &-fav {
              &.logged-in {
                svg[class*='icon-'] {
                  path {
                    fill: $subcategory;
                  }
                }
              }

              &.active {
                background-color: rgba($subcategory, 0.15);
                box-shadow: inset 0 0.4rem 0.4rem rgba($subcategory, 0.04);
                color: $subcategory;

                svg[class*='icon-'] {
                  path {
                    fill: $subcategory;
                  }
                }
              }
            }
          }
        }

        .link-lang {
          flex: 0 0 33.333333%;
          width: 33.333333%;
          background-color: transparent;
          position: relative;
          text-align: center;

          &:before {
            background-color: $border;
            content: '';
            display: block;
            width: 0.1rem;
            height: 4.6rem;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          span {
            background-color: $placeholder;
            border-radius: 0.4rem;
            color: $white;
            display: block;
            font-size: 1.4rem;
            line-height: 2.4rem;
            margin: 0 auto;
            width: 3.8rem;
            height: 2.4rem;
            text-align: center;
          }
        }
      }
    }

    @media screen and (max-width: $smartphone-small) {
      &.open {
        display: block;
      }
    }

    &_menu {
      background-color: $white;
      box-shadow: 0 0.4rem 0.8rem rgba($text, 0.1);
      font-weight: $font-bold;
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 2.4rem 1.2rem 2.4rem 3.6rem;
      width: 31.8rem;
      overflow-y: auto;

      .nav {
        &-item {
          width: 100%;
        }

        &-link {
          border-radius: 0.4rem;
          padding: 1rem 0.8rem 1rem 2rem;
          position: relative;

          .icon {
            position: absolute;
            top: 50%;
            left: 0.8rem;
            transform: translateY(-50%);
          }

          &.active {
            background-color: rgba($subcategory, 0.15);
            color: $subcategory;
          }
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        width: 50%;
        padding: 1.2rem 2rem;
      }

      @media screen and (max-width: $smartphone-small) {
        width: 100%;
        box-shadow: none;

        .nav-link {
          padding-right: 4.8rem;

          &:before {
            background: url(../../images/ic-arrow_d.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 0.74rem;
            height: 1.2rem;
            position: absolute;
            top: 50%;
            right: 0.8rem;
            transform: translateY(-50%);
          }
        }
      }
    }

    &_content {
      flex-grow: 2;

      .tab-content {
        & > .active {
          display: flex;
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        flex: 0 0 50%;
        width: 50%;
      }

      @media screen and (max-width: $smartphone-small) {
        display: none;
      }
    }

    &_submenu {
      flex-grow: 2;
      display: flex;
      padding: 3.4rem 0 3.4rem 3.3rem;

      @media screen and (max-width: $hd - 130) {
        display: block;
      }

      .submenu-column {
        padding-right: 1.5rem;

        li {
          padding-bottom: 1.2rem;
        }
      }
    }

    &_item {
      background-color: $border;
      width: 33.2rem;
      padding: 2.4rem 3.6rem 6.2rem;

      h3 {
        margin: 0 0 2.4rem;
      }

      .item-product {
        border: none;

        .btn-buy {
          width: 4.8rem;
          padding: 0;

          .icon-basket {
            margin-right: 0;
          }
        }
      }

      @media screen and (max-width: $tablet-middle) {
        display: none;
      }
    }

    &_more {
      display: none;

      @media screen and (max-width: $smartphone-small) {
        display: block;
        border-top: 0.1rem solid $border;
        font-size: 1.6rem;
        font-weight: $font-bold;
        line-height: 2rem;
        padding: 1.6rem 1.8rem;

        .menu-spec {
          margin-bottom: 1.7rem;

          a {
            display: block;
            padding: 1.4rem 0;
          }
        }

        .header-apps {
          display: flex;

          li {
            margin: 0 0.8rem 0 0;
          }

          a {
            width: 4.8rem;
            height: 4.8rem;
          }
        }
      }
    }
  }

  &-list {
    .section-also {
      padding-bottom: 3rem;

      @media screen and (max-width: $tablet) {
        padding: 0 2.2rem;
      }

      @media screen and (max-width: $smartphone-smallest) {
        padding: 0 1.3rem;
      }
    }

    .catalog-item {
      margin-bottom: 3rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      @media screen and (max-width: $tablet) {
        margin-bottom: 1rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        padding: 0 0.5rem;
      }
    }
  }

  &-sort {
    &_drop {
      padding-top: 0.6rem;
      position: relative;

      .category-radio {
        display: block;
        position: absolute;
        right: 0;
        top: 3.4rem;
        z-index: 4;
        background-color: $white;
        box-shadow: 0 0.1rem 0.6rem 0 rgba($shadow, 0.4);
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 30rem;
        white-space: nowrap;

        .form-radio {
          margin: 0 2rem;

          &:first-child {
            margin-top: 1.5rem;
          }
        }
      }
    }

    &_caption {
      cursor: pointer;
      font-size: 1.4rem;
      padding: 0 1.4rem 0 0;
      position: relative;

      &:after {
        background: url(../../images/ic-arrow-drop.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 0.9rem;
        height: 0.5rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 1;
      }
    }
  }

  &-filter-sort {
    position: relative;
    z-index: 5;

    & > .container {
      display: flex;
      justify-content: space-between;
    }
  }
}

.catalog {
  &-menu {
    &_item.single a {
      &::after {
        content: none;
      }
    }

    a {
      cursor: pointer;
    }
  }
}
